<!-- eslint-disable vue/no-v-html -->
<template>
	<WSection :title="$t('customer.administration.deactivate_customer') + ' :'">
		<v-flex v-t="'customer.administration.deactivate_customer_desc'" xs12 />
		<v-flex text-xs-right xs12>
			<v-btn color="error" flat round @click="dialog = true">{{ $t('customer.administration.deactivate_customer') }}</v-btn>
			<WDialog v-model="dialog" error max-width="500" :title="$t('customer.administration.deactivate_customer')">
				<v-flex v-t="'customer.administration.deactivate_customer_dialog.first'" my-2 />
				<v-flex v-t="'customer.administration.deactivate_customer_dialog.second'" my-2 />
				<v-flex v-t="'customer.administration.deactivate_customer_dialog.third'" my-2 />
				<v-flex my-2 v-html="$t('customer.administration.deactivate_customer_dialog.fourth', { name: company.company })" />
				<v-flex my-2>
					<v-text-field v-model="confirmationName" color="error" />
				</v-flex>
				<template v-slot:actions>
					<v-btn color="error" class="ml-auto mr-0" :disabled="!isNameConfirmed" flat round @click="archiveCustomer()">{{
						$t('customer.administration.deactivate_customer')
					}}</v-btn>
				</template>
			</WDialog>
		</v-flex>
	</WSection>
</template>

<script>
import CustomersManagerModuleGuard from '@/mixins/ModulesGuards/CustomersManager/CustomersManagerModuleGuard'
import SanitizedConfirmation from "@/mixins/CustomersManager/CustomerDetails/Administration/SanitizedConfirmation";

export default {
	name: 'CompanyDeactivator',
	mixins: [CustomersManagerModuleGuard, SanitizedConfirmation],
	props: {
		company: {
			required: true,
			type: Object
		}
	},
	data: function () {
		return {
			confirmationName: '',
			dialog: false
		}
	},
	methods: {
		archiveCustomer: function () {
			this.service.archiveCustomer(this.company)
		}
	}
}
</script>
